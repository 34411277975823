import auth from "@/middleware/auth";

const aScanIndex = () =>
  import("../views/Scans/aScan/index" /* webpackChunkName: "ascanIndex" */);
const aScanEdit = () =>
  import("../views/Scans/aScan/edit" /* webpackChunkName: "ascanEdit" */);
const bScanIndex = () =>
  import("../views/Scans/bScan/index" /* webpackChunkName: "bscanIndex" */);
const bScanEdit = () =>
  import("../views/Scans/bScan/edit" /* webpackChunkName: "bscanEdit" */);
const cScanIndex = () =>
  import("../views/Scans/cScan/index" /* webpackChunkName: "cscanIndex" */);
const cScanEdit = () =>
  import("../views/Scans/cScan/edit" /* webpackChunkName: "cscanEdit" */);
const dScanIndex = () =>
  import("../views/Scans/dScan/index" /* webpackChunkName: "dscanIndex" */);
const dScanEdit = () =>
  import("../views/Scans/dScan/edit" /* webpackChunkName: "dscanEdit" */);
const eScanIndex = () =>
  import("../views/Scans/eScan/index" /* webpackChunkName: "escanIndex" */);
const eScanEdit = () =>
  import("../views/Scans/eScan/edit" /* webpackChunkName: "escanEdit" */);
const fScanIndex = () =>
  import("../views/Scans/fScan/index" /* webpackChunkName: "fscanIndex" */);
const fScanEdit = () =>
  import("../views/Scans/fScan/edit" /* webpackChunkName: "fscanEdit" */);
const gScanIndex = () =>
  import("../views/Scans/gScan/index" /* webpackChunkName: "gscanIndex" */);
const gScanEdit = () =>
  import("../views/Scans/gScan/edit" /* webpackChunkName: "gscanEdit" */);
const hScanIndex = () =>
  import("../views/Scans/hScan/index" /* webpackChunkName: "hscanIndex" */);
const hScanEdit = () =>
  import("../views/Scans/hScan/edit" /* webpackChunkName: "hscanEdit" */);
const iScanIndex = () =>
  import("../views/Scans/iScan/index" /* webpackChunkName: "iscanIndex" */);
const iScanEdit = () =>
  import("../views/Scans/iScan/edit" /* webpackChunkName: "iscanEdit" */);
const jScanIndex = () =>
  import("../views/Scans/jScan/index" /* webpackChunkName: "jscanIndex" */);
const jScanEdit = () =>
  import("../views/Scans/jScan/edit" /* webpackChunkName: "jscanEdit" */);
const kScanIndex = () =>
  import("../views/Scans/kScan/index" /* webpackChunkName: "kscanIndex" */);
const kScanEdit = () =>
  import("../views/Scans/kScan/edit" /* webpackChunkName: "kscanEdit" */);

const lScanIndex = () =>
  import("../views/Scans/lScan/index" /* webpackChunkName: "lscanIndex" */);
const lScanEdit = () =>
  import("../views/Scans/lScan/edit" /* webpackChunkName: "lscanEdit" */);

const mScanIndex = () =>
  import("../views/Scans/mScan/index" /* webpackChunkName: "mscanIndex" */);
const mScanEdit = () =>
  import("../views/Scans/mScan/edit" /* webpackChunkName: "mscanEdit" */);

const nScanIndex = () =>
  import("../views/Scans/nScan/index" /* webpackChunkName: "nscanIndex" */);
const nScanEdit = () =>
  import("../views/Scans/nScan/edit" /* webpackChunkName: "nscanEdit" */);

const oScanIndex = () =>
  import("../views/Scans/oScan/index" /* webpackChunkName: "oscanIndex" */);
const oScanEdit = () =>
  import("../views/Scans/oScan/edit" /* webpackChunkName: "nsoanEdit" */);

const pScanIndex = () =>
  import("../views/Scans/pScan/index" /* webpackChunkName: "pscanIndex" */);
const pScanEdit = () =>
  import("../views/Scans/pScan/edit" /* webpackChunkName: "pscanEdit" */);

const qScanIndex = () =>
  import("../views/Scans/qScan/index" /* webpackChunkName: "qscanIndex" */);
const qScanEdit = () =>
  import("../views/Scans/qScan/edit" /* webpackChunkName: "qscanEdit" */);

const rScanIndex = () =>
  import("../views/Scans/rScan/index" /* webpackChunkName: "rscanIndex" */);
const rScanEdit = () =>
  import("../views/Scans/rScan/edit" /* webpackChunkName: "rscanEdit" */);

const tScanIndex = () =>
  import("../views/Scans/tScan/index" /* webpackChunkName: "tscanIndex" */);
const tScanEdit = () =>
  import("../views/Scans/tScan/edit" /* webpackChunkName: "tscanEdit" */);

const uScanIndex = () =>
  import("../views/Scans/uScan/index" /* webpackChunkName: "uscanIndex" */);
const uScanEdit = () =>
  import("../views/Scans/uScan/edit" /* webpackChunkName: "uscanEdit" */);

const abScanIndex = () =>
  import("../views/Scans/abScan/index" /* webpackChunkName: "abscanIndex" */);
const abScanEdit = () =>
  import("../views/Scans/abScan/edit" /* webpackChunkName: "abscanEdit" */);

const routes = [
  {
    path: "/a-scan-index/:id",
    component: aScanIndex,
    name: "aScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/a-scan-edit/:id",
    component: aScanEdit,
    name: "aScanEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/b-scan-index/:id",
    component: bScanIndex,
    name: "bScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/b-scan-edit/:id",
    component: bScanEdit,
    name: "bScanEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/c-scan-index/:id",
    component: cScanIndex,
    name: "cScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/c-scan-edit/:id",
    component: cScanEdit,
    name: "cScanEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/d-scan-index/:id",
    component: dScanIndex,
    name: "dScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/d-scan-edit/:id",
    component: dScanEdit,
    name: "dScanEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/e-scan-index/:id",
    component: eScanIndex,
    name: "eScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/e-scan-edit/:id",
    component: eScanEdit,
    name: "eScanEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/f-scan-index/:id",
    component: fScanIndex,
    name: "fScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/f-scan-edit/:id",
    component: fScanEdit,
    name: "fScanEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/g-scan-index/:id",
    component: gScanIndex,
    name: "gScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/g-scan-edit/:id",
    component: gScanEdit,
    name: "gScanEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/h-scan-index/:id",
    component: hScanIndex,
    name: "hScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/h-scan-edit/:id",
    component: hScanEdit,
    name: "hScanEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/i-scan-index/:id",
    component: iScanIndex,
    name: "iScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/i-scan-edit/:id",
    component: iScanEdit,
    name: "iScanEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/j-scan-index/:id",
    component: jScanIndex,
    name: "jScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/j-scan-edit/:id",
    component: jScanEdit,
    name: "jScanEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/k-scan-index/:id",
    component: kScanIndex,
    name: "kScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/k-scan-edit/:id",
    component: kScanEdit,
    name: "kScanEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/l-scan-index/:id",
    component: lScanIndex,
    name: "lScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/l-scan-edit/:id",
    component: lScanEdit,
    name: "lScanEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/m-scan-index/:id",
    component: mScanIndex,
    name: "mScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/m-scan-edit/:id",
    component: mScanEdit,
    name: "mScanEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/n-scan-index/:id",
    component: nScanIndex,
    name: "nScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/n-scan-edit/:id",
    component: nScanEdit,
    name: "nScanEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/o-scan-index/:id",
    component: oScanIndex,
    name: "oScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/o-scan-edit/:id",
    component: oScanEdit,
    name: "oScanEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/p-scan-index/:id",
    component: pScanIndex,
    name: "pScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/p-scan-edit/:id",
    component: pScanEdit,
    name: "pScanEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/q-scan-index/:id",
    component: qScanIndex,
    name: "qScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/q-scan-edit/:id",
    component: qScanEdit,
    name: "qScanEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/r-scan-index/:id",
    component: rScanIndex,
    name: "rScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/r-scan-edit/:id",
    component: rScanEdit,
    name: "rScanEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/t-scan-index/:id",
    component: tScanIndex,
    name: "tScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/t-scan-edit/:id",
    component: tScanEdit,
    name: "tScanEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/u-scan-index/:id",
    component: uScanIndex,
    name: "uScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/u-scan-edit/:id",
    component: uScanEdit,
    name: "uScanEdit",
    meta: { middleware: [auth] },
  },

  {
    path: "/ab-scan-index/:id",
    component: abScanIndex,
    name: "abScanIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/ab-scan-edit/:id",
    component: abScanEdit,
    name: "abScanEdit",
    meta: { middleware: [auth] },
  },
];
export default routes;
