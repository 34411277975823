import { getError } from "@/utils/helpers";
import UpdateService from "@/services/Update/updateService";

export const namespaced = true;

export const state = {
  clients: [],
  loading: false,
  error: null,
};

export const mutations = {
  SET_CLIENTS(state, clients) {
    state.clients = clients;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  getClientsA({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsA()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsB({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsB()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsC({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsC()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsD({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsD()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsE({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsE()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsF({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsF()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsG({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsG()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsH({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsH()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsI({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsI()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsJ({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsJ()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsK({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsK()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsL({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsL()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsM({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsM()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsN({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsN()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsO({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsO()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsP({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsP()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsQ({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsQ()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsR({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsR()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsS({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsS()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsT({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsT()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsU({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsU()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsV({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsV()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsW({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsW()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsX({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsX()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsY({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsY()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsZ({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsZ()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  getClientsAB({ commit }) {
    commit("SET_LOADING", true);

    UpdateService.getClientsAB()
      .then((response) => {
        commit("SET_CLIENTS", response.data.data);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  setDefault({ commit }) {
    commit("SET_LOADING", false);
    commit("SET_ERROR", null);
    commit("SET_CLIENTS", []);
  },
};
export const getters = {
  clients: (state) => {
    return state.clients;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
