import auth from "@/middleware/auth";

const updateIndexA = () =>
  import("../views/scanIndex/indexA" /* webpackChunkName: "aUpdateIndex" */);
const updateIndexB = () =>
  import("../views/scanIndex/indexB" /* webpackChunkName: "bUpdateIndex" */);
const updateIndexC = () =>
  import("../views/scanIndex/indexC" /* webpackChunkName: "cUpdateIndex" */);
const updateIndexD = () =>
  import("../views/scanIndex/indexD" /* webpackChunkName: "dUpdateIndex" */);
const updateIndexE = () =>
  import("../views/scanIndex/indexE" /* webpackChunkName: "eUpdateIndex" */);
const updateIndexF = () =>
  import("../views/scanIndex/indexF" /* webpackChunkName: "fUpdateIndex" */);
const updateIndexG = () =>
  import("../views/scanIndex/indexG" /* webpackChunkName: "gUpdateIndex" */);
const updateIndexH = () =>
  import("../views/scanIndex/indexH" /* webpackChunkName: "hUpdateIndex" */);
const updateIndexI = () =>
  import("../views/scanIndex/indexI" /* webpackChunkName: "iUpdateIndex" */);
const updateIndexJ = () =>
  import("../views/scanIndex/indexJ" /* webpackChunkName: "jUpdateIndex" */);
const updateIndexK = () =>
  import("../views/scanIndex/indexK" /* webpackChunkName: "kUpdateIndex" */);
const updateIndexL = () =>
  import("../views/scanIndex/indexL" /* webpackChunkName: "lUpdateIndex" */);
const updateIndexM = () =>
  import("../views/scanIndex/indexM" /* webpackChunkName: "mUpdateIndex" */);
const updateIndexN = () =>
  import("../views/scanIndex/indexN" /* webpackChunkName: "nUpdateIndex" */);
const updateIndexO = () =>
  import("../views/scanIndex/indexO" /* webpackChunkName: "oUpdateIndex" */);
const updateIndexP = () =>
  import("../views/scanIndex/indexP" /* webpackChunkName: "pUpdateIndex" */);
const updateIndexQ = () =>
  import("../views/scanIndex/indexQ" /* webpackChunkName: "qUpdateIndex" */);
const updateIndexR = () =>
  import("../views/scanIndex/indexR" /* webpackChunkName: "rUpdateIndex" */);
const updateIndexS = () =>
  import("../views/scanIndex/indexS" /* webpackChunkName: "sUpdateIndex" */);
const updateIndexT = () =>
  import("../views/scanIndex/indexT" /* webpackChunkName: "tUpdateIndex" */);
const updateIndexU = () =>
  import("../views/scanIndex/indexU" /* webpackChunkName: "uUpdateIndex" */);
const updateIndexV = () =>
  import("../views/scanIndex/indexV" /* webpackChunkName: "vUpdateIndex" */);
const updateIndexW = () =>
  import("../views/scanIndex/indexW" /* webpackChunkName: "wUpdateIndex" */);
const updateIndexX = () =>
  import("../views/scanIndex/indexX" /* webpackChunkName: "xUpdateIndex" */);
const updateIndexY = () =>
  import("../views/scanIndex/indexY" /* webpackChunkName: "yUpdateIndex" */);
const updateIndexZ = () =>
  import("../views/scanIndex/indexZ" /* webpackChunkName: "zUpdateIndex" */);

const updateIndexAB = () =>
  import("../views/scanIndex/indexAB" /* webpackChunkName: "abUpdateIndex" */);

const routes = [
  {
    path: "/index-a-scan",
    component: updateIndexA,
    name: "updateAindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-b-scan",
    component: updateIndexB,
    name: "updateBindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-c-scan",
    component: updateIndexC,
    name: "updateCindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-d-scan",
    component: updateIndexD,
    name: "updateDindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-e-scan",
    component: updateIndexE,
    name: "updateEindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-f-scan",
    component: updateIndexF,
    name: "updateFindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-g-scan",
    component: updateIndexG,
    name: "updateGindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-h-scan",
    component: updateIndexH,
    name: "updateHindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-i-scan",
    component: updateIndexI,
    name: "updateIindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-j-scan",
    component: updateIndexJ,
    name: "updateJindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-k-scan",
    component: updateIndexK,
    name: "updateKindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-l-scan",
    component: updateIndexL,
    name: "updateLindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-m-scan",
    component: updateIndexM,
    name: "updateMindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-n-scan",
    component: updateIndexN,
    name: "updateNindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-o-scan",
    component: updateIndexO,
    name: "updateOindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-p-scan",
    component: updateIndexP,
    name: "updatePindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-q-scan",
    component: updateIndexQ,
    name: "updateQindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-r-scan",
    component: updateIndexR,
    name: "updateRindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-s-scan",
    component: updateIndexS,
    name: "updateSindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-t-scan",
    component: updateIndexT,
    name: "updateTindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-u-scan",
    component: updateIndexU,
    name: "updateUindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-v-scan",
    component: updateIndexV,
    name: "updateVindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-w-scan",
    component: updateIndexW,
    name: "updateWindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-x-scan",
    component: updateIndexX,
    name: "updateXindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-y-scan",
    component: updateIndexY,
    name: "updateYindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-z-scan",
    component: updateIndexZ,
    name: "updateZindex",
    meta: { middleware: [auth] },
  },
  {
    path: "/index-ab-scan",
    component: updateIndexAB,
    name: "updateABindex",
    meta: { middleware: [auth] },
  },
];
export default routes;
