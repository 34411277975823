import Vue from "vue";
import VueRouter from "vue-router";
import teamRoutes from "../router/team";
import userRoutes from "@/router/user";
import clientRoutes from "@/router/clients";
import pcmRoutes from "@/router/pcm";
import scanRoutes from "@/router/scan";
import changeRoutes from "@/router/change";
import presRoutes from "@/router/pres";
import landingRoutes from "@/router/landing";
import updateRoutes from "@/router/update";
import massRoutes from "@/router/mass";
Vue.use(VueRouter);
import middlewarePipeline from "@/router/middlewarePipeline";
import store from "@/store/index";

let allRoutes = [];
allRoutes = allRoutes.concat(
  teamRoutes,
  userRoutes,
  clientRoutes,
  pcmRoutes,
  scanRoutes,
  changeRoutes,
  presRoutes,
  landingRoutes,
  updateRoutes,
  massRoutes
);

const routes = allRoutes;

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware;
  const context = { to, from, next, store };

  if (!middleware) {
    return next();
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
