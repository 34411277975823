import auth from "@/middleware/auth";

const massIndex = () =>
  import("@/views/MassAction/index" /* webpackChunkName: "massIndex" */);
const massEdit = () =>
  import("@/views/MassAction/edit" /* webpackChunkName: "massEdit" */);

const massCreate = () =>
  import("@/views/MassAction/create" /* webpackChunkName: "massCreate" */);

const massClients = () =>
  import(
    "@/views/MassAction/indexClients.vue" /* webpackChunkName: "massClientsIndex" */
  );

const routes = [
  {
    path: "/mass-index/",
    component: massIndex,
    name: "massIndex",
    meta: { middleware: [auth] },
  },
  {
    path: "/mass-edit/:id",
    component: massEdit,
    name: "massEdit",
    meta: { middleware: [auth] },
  },
  {
    path: "/mass-create/",
    component: massCreate,
    name: "massCreate",
    meta: { middleware: [auth] },
  },
  {
    path: "/mass-clients/:id",
    component: massClients,
    name: "massClients",
    meta: { middleware: [auth] },
  },
];
export default routes;
