import * as API from "@/services/API";

export default {
  getClientsA() {
    return API.apiClient.get(`/aScans/`);
  },
  getClientsB() {
    return API.apiClient.get(`/bScans/`);
  },
  getClientsC() {
    return API.apiClient.get(`/cScans/`);
  },
  getClientsD() {
    return API.apiClient.get(`/dScans/`);
  },
  getClientsE() {
    return API.apiClient.get(`/eScans/`);
  },
  getClientsF() {
    return API.apiClient.get(`/fScans/`);
  },
  getClientsG() {
    return API.apiClient.get(`/gScans/`);
  },
  getClientsH() {
    return API.apiClient.get(`/hScans/`);
  },
  getClientsI() {
    return API.apiClient.get(`/iScans/`);
  },
  getClientsJ() {
    return API.apiClient.get(`/jScans/`);
  },
  getClientsK() {
    return API.apiClient.get(`/kScans/`);
  },
  getClientsL() {
    return API.apiClient.get(`/lScans/`);
  },
  getClientsM() {
    return API.apiClient.get(`/mScans/`);
  },
  getClientsN() {
    return API.apiClient.get(`/nScans/`);
  },
  getClientsO() {
    return API.apiClient.get(`/oScans/`);
  },
  getClientsP() {
    return API.apiClient.get(`/pScans/`);
  },
  getClientsQ() {
    return API.apiClient.get(`/qScans/`);
  },
  getClientsR() {
    return API.apiClient.get(`/rScans/`);
  },
  getClientsS() {
    return API.apiClient.get(`/sScans/`);
  },
  getClientsT() {
    return API.apiClient.get(`/tScans/`);
  },
  getClientsU() {
    return API.apiClient.get(`/uScans/`);
  },
  getClientsV() {
    return API.apiClient.get(`/vScans/`);
  },
  getClientsW() {
    return API.apiClient.get(`/wScans/`);
  },
  getClientsX() {
    return API.apiClient.get(`/xScans/`);
  },
  getClientsY() {
    return API.apiClient.get(`/yScans/`);
  },
  getClientsZ() {
    return API.apiClient.get(`/zScans/`);
  },
  getClientsAB() {
    return API.apiClient.get(`/abScans/`);
  },
};
